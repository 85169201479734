<template>
  <section ref="footerBlock" class="footer-main">
    <div class="row-4-desk">
      <!-- Riga blocchi testi & CTA -->
      <div class="footer-main__wrap">
        <div class="footer-main__text-wrap">
          <p>
            <strong>Giulia Pasqual</strong></p>
           <p> <strong> Associate Professor</strong><br />
            Department of Surgery Oncology and Gastroenterology<br />
            Università degli Studi di Padova<br /></p>
            <p class="m-bottom-16px"><strong>Principal Investigator</strong><br/>
            Veneto Institute of Oncology IOV-IRCCS



          </p>
          <p class="m-top-24px"><a
      href="https://www.iubenda.com/privacy-policy/57910588"
      class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
      title="Privacy Policy"
    >
      Privacy Policy
    </a></p>
    <p class="p-top-8px">
      <a href="https://www.quamm.it/" class="link-underline-1 text-x-small " target="_blank">Quamm</a>
    </p>
        </div>

        <div class="footer-main__text-wrap_logos">
          <img
            src="@/assets/img/logos/universita.svg"
            width="222"
            height="99"
            alt="Università degli Studi di Padova"
            class="footer-logo-university"
          />

          <img
            src="@/assets/img/logos/iov.png"
            width="122"
            height="130"
            alt="Istituto Oncologico Veneto"
            class="footer-logo-iov"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterMain',
}
</script>

<script setup>
onMounted(() => {
  if (!window.iubendaScriptAdded) {
    const script = document.createElement("script");
    script.src = "https://cdn.iubenda.com/iubenda.js";
    script.async = true;
    document.body.appendChild(script);
    window.iubendaScriptAdded = true;
  }
})
</script>

<style lang="scss">
@import './style.scss';
</style>
